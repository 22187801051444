import { DeleteOutlined, HeartFilled, HeartOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, Popconfirm, Select } from "antd";
import React from "react";
import { PlatformInterface } from "../../../types/types";
import "../../../../style/platformsInput.scss";

const PlatformInputs = ({
  action,
  platforms,
  setPlatforms,
  platformList,
}: any) => {
  const handlePlatformChange = (
    id: string,
    field: keyof PlatformInterface,
    value: any
  ) => {
    const modifiedValue = (platform: any) => {
      if (!platform.newPlatform) {
        if (field === "modified" && value === "delete") {
          return "delete";
        } else {
          return "update";
        }
      } else if (
        platform.newPlatform &&
        field === "modified" &&
        value === "delete"
      ) {
        return "delete";
      }
    };
    setPlatforms((prevPlatforms: any) =>
      prevPlatforms.map((platform: PlatformInterface) =>
        platform.platformId === id
          ? {
              ...platform,
              [field]: value,
              modified: modifiedValue(platform),
            }
          : platform
      )
    );
  };

  const CheckboxComponent = ({ platform }: any) => {
    return (
      <Checkbox
        className="platform-checkbox"
        onChange={(e) => {
          handlePlatformChange(
            platform.platformId,
            "platformFavorite",
            e.target.checked
          );
        }}
        defaultChecked={action === "update" ? platform.platformFavorite : false}
      >
        {platform.platformFavorite ? (
          <HeartFilled
            style={{
              color: "#ff5d8f",
              fontSize: "20px",
              padding: "4px 0px",
            }}
          />
        ) : (
          <HeartOutlined
            style={{
              color: "#ff5d8f",
              fontSize: "20px",
              padding: "4px 0px",
            }}
          />
        )}
      </Checkbox>
    );
  };

  const SelectTypes = ({ platform }: { platform: PlatformInterface }) => {
    return (
      <Select
        placeholder="Select Platform"
        value={
          action === "update" && platform.platformName
            ? platform.platformName
            : undefined
        }
        options={
          platformList.__type?.enumValues
            ? platformList.__type?.enumValues.map((media: any) => ({
                value: media.name,
                label: media.name,
              }))
            : []
        }
        onChange={(value) => {
          handlePlatformChange(platform.platformId, "platformName", value);
        }}
      ></Select>
    );
  };

  return (
    platformList?.__type?.enumValues &&
    platforms.filter(
      (platform: PlatformInterface) => platform?.modified !== "delete"
    ).length > 0 &&
    platforms
      .filter((platform: PlatformInterface) => platform?.modified !== "delete")
      .map((platform: PlatformInterface) => (
        <div className="platform-inputs">
          <span className="platform-input-container" key={platform.platformId}>
            <div className="responsive-select-display">
              <SelectTypes platform={platform} />
            </div>
            <Input
              className="platform-input"
              placeholder="Enter platform link"
              defaultValue={action === "update" ? platform.platformUrl : ""}
              onChange={(e) => {
                handlePlatformChange(
                  platform.platformId,
                  "platformUrl",
                  e.target.value
                );
              }}
              addonBefore={<SelectTypes platform={platform} />}
              addonAfter={<CheckboxComponent platform={platform} />}
            />
          </span>
          <Popconfirm
            title="Are you sure you want to delete this platform?"
            onConfirm={() =>
              handlePlatformChange(platform.platformId, "modified", "delete")
            }
            okText="Yes"
            cancelText="No"
          >
            <Button type="default" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      ))
  );
};

export default PlatformInputs;
