import { CheckSquareOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, List, Select, Tag } from "antd";
import React from "react";
import {
  CreateTasksDocument,
  UpdateTasksDocument,
  useGetTaskStatusQuery,
} from "../../../../generated/graphql";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import "../../../../style/person-tasks-component.scss";
import { useParams } from "react-router-dom";

interface PersonTasksProps {
  loggedUserId: string;
  personId: string;
  personTasks?: any[] | undefined;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  refetchTasks?: () => void;
}

const PersonTasks: React.FC<PersonTasksProps> = ({
  loggedUserId,
  personId,
  personTasks,
  setRefetch,
  refetchTasks,
}) => {
  const { action } = useParams();
  const [form] = Form.useForm();
  const [editMode, setEditMode] = React.useState(false);
  const { data: taskStatus } = useGetTaskStatusQuery();
  const taskStatuses = taskStatus?.__type?.enumValues;
  const [updateTask] = useMutation(UpdateTasksDocument);
  const [createTasks] = useMutation(CreateTasksDocument);

  const findTask = (taskId: string) => {
    return personTasks?.find((task) => task.taskId === taskId);
  };

  const handleFormSubmit = async (values: any) => {
    try {
      const createData = {
        ...values,
        taskId: uuidv4(),
        taskCreatedAt: dayjs().format("YYYY-MM-DD"),
        taskUpdatedAt: dayjs().format("YYYY-MM-DD"),
        taskDueDate: dayjs().format("YYYY-MM-DD"),
        taskOwner: {
          connect: {
            where: {
              node: {
                userId: loggedUserId,
              },
            },
          },
        },
        taskPerson: {
          connect: {
            where: {
              node: {
                personId: personId,
              },
            },
          },
        },
      };

      await createTasks({
        variables: { input: { ...createData } },
      }).then((res) => {
        if (!res.errors) {
          form.resetFields();
          setEditMode(false);
          setRefetch(true);
        }
      });
    } catch (error) {
      console.error("Error creating a task", error);
    }
  };

  return (
    <Card
      title={
        <>
          <CheckSquareOutlined /> Tasks
        </>
      }
      className="card-container task-card "
      extra={
        action !== "create" && (
          <Button type="link" onClick={() => setEditMode(!editMode)}>
            {editMode ? (
              "Cancel"
            ) : (
              <>
                <PlusCircleOutlined /> Add
              </>
            )}
          </Button>
        )
      }
    >
      {editMode && (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
          initialValues={{
            taskName: "",
            taskDescription: "",
            taskStatus: "PENDING",
          }}
        >
          <Form.Item
            label="Task Name"
            name="taskName"
            rules={[{ required: true, message: "Task Name is required" }]}
          >
            <Input placeholder="Enter task name" />
          </Form.Item>
          <Form.Item label="Task Description" name="taskDescription">
            <Input.TextArea placeholder="Enter task description" />
          </Form.Item>
          <Form.Item
            label="Task Status"
            name="taskStatus"
            rules={[{ required: true, message: "Task Status is required" }]}
          >
            <Select placeholder="Select a task status">
              {taskStatuses &&
                taskStatuses.map((status: any) => (
                  <Select.Option key={status.name} value={status.name}>
                    {status.name.replace(/_/g, " ")}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item>
            {action === "create" ? (
              ""
            ) : (
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            )}
          </Form.Item>
        </Form>
      )}
      {personTasks && personTasks.length > 0 ? (
        <List
          className="task-list"
          itemLayout="horizontal"
          dataSource={personTasks}
          renderItem={(task) => (
            <List.Item>
              <div>
                <p className="text">
                  <b>{task.taskName}</b>
                </p>
                {task.taskDescription && (
                  <p className="text">{task.taskDescription}</p>
                )}
              </div>
              <div>
                {refetchTasks
                  ? taskStatuses?.map((status) => (
                      <Tag.CheckableTag
                        key={`${task.taskId}-${status.name}`}
                        checked={
                          findTask(task.taskId)?.taskStatus === status.name &&
                          findTask(task.taskId)?.taskId === task.taskId
                            ? true
                            : false
                        }
                        onChange={async (checked) => {
                          if (checked) {
                            await updateTask({
                              variables: {
                                update: {
                                  taskStatus: status.name,
                                  taskUpdatedAt: dayjs().format("YYYY-MM-DD"),
                                },
                                where: {
                                  taskId: task.taskId,
                                  taskPerson_SINGLE: {
                                    personId: personId,
                                  },
                                  taskOwner: { userId: loggedUserId },
                                },
                              },
                            }).then(() => {
                              refetchTasks();
                            });
                          }
                        }}
                      >
                        {status.name.replace(/_/g, " ")}
                      </Tag.CheckableTag>
                    ))
                  : ""}
              </div>
            </List.Item>
          )}
        />
      ) : (
        "No Tasks"
      )}
    </Card>
  );
};

export default PersonTasks;
