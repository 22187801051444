import { Card, List, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import DoughnutChart from "../../reusable-components/DonutChart";
import {
  UpdateTasksDocument,
  useGetPersonTasksQuery,
  useGetTaskStatusQuery,
} from "../../../generated/graphql";
import { getCookie } from "../../hooks/cookie";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import "../../../style/dashboard.scss";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const [dataChart, setDataChart] = useState<any[]>([]);
  const [taskList, setTaskList] = useState<any[]>([]);
  const backgroundColors = ["#36cfc9", "#4096ff", "#bae637", "#ff4d4f"];
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;
  const {
    data: tasks,
    loading: tasksLoading,
    refetch: refetchTasks,
  } = useGetPersonTasksQuery({
    variables: {
      where: {
        taskOwner: { userId: loggedUserId },
      },
    },
  });
  const { data: taskStatus } = useGetTaskStatusQuery();
  const taskStatuses = taskStatus?.__type?.enumValues;
  const [updateTask] = useMutation(UpdateTasksDocument);

  useEffect(() => {
    if (tasks?.tasks && taskStatuses) {
      //For Chart
      const statusCounts = tasks.tasks.reduce(
        (acc: { [key: string]: number }, task) => {
          acc[task.taskStatus] = (acc[task.taskStatus] || 0) + 1;
          return acc;
        },
        {}
      );

      setDataChart(
        taskStatuses.map((status) => ({
          label: status.name.replace(/_/g, " "),
          amount: statusCounts[status.name] || 0,
        }))
      );

      //For Task List
      const inProgressTasks = tasks.tasks.filter(
        (task) =>
          task.taskStatus ===
          taskStatuses.find((status) => status.name === "IN_PROGRESS")?.name
      );
      const pendingTasks = tasks.tasks.filter(
        (task) =>
          task.taskStatus ===
          taskStatuses.find((status) => status.name === "PENDING")?.name
      );
      setTaskList([...inProgressTasks, ...pendingTasks].slice(0, 5));
    }
    if (!tasks?.tasks[0]) {
      setDataChart([]);
    }
  }, [taskStatuses, tasks]);

  const findTask = (taskId: string) => {
    return taskList?.find((task) => task.taskId === taskId);
  };

  return (
    <div className="dashboard">
      <div className="page-title-header">
        <h1
          style={{
            backgroundColor: "white",
            width: "fit-content",
            padding: "16px 16px",
            borderRadius: "8px",
            color: "#141414",
            opacity: 0.8,
          }}
        >
          DASHBOARD
        </h1>
      </div>
      <div className="dashboard-content">
        <Card
          title="Work in progress"
          extra={<a href="/tasks">View All</a>}
          className="card tasks-list"
        >
          <List
            loading={tasksLoading}
            dataSource={taskList}
            renderItem={(task) => (
              <List.Item
                className="task-item"
                onClick={(e) => {
                  if ((e.target as Element).closest(".non-clickable-column")) {
                    e.stopPropagation();
                    return;
                  }
                  navigate(`/person/${task.taskPerson[0]?.personName}`, {
                    state: { personId: task.taskPerson[0]?.personId },
                  });
                }}
              >
                <div>
                  <p className="text">
                    <b>{task.taskName}</b>
                  </p>
                </div>
                <div className="non-clickable-column">
                  {taskStatuses?.map((status) => (
                    <Tag.CheckableTag
                      key={`${task.taskId}-${status.name}`}
                      checked={
                        findTask(task.taskId)?.taskStatus === status.name &&
                        findTask(task.taskId)?.taskId === task.taskId
                          ? true
                          : false
                      }
                      onChange={async (checked) => {
                        if (checked) {
                          await updateTask({
                            variables: {
                              update: {
                                taskStatus: status.name,
                                taskUpdatedAt: dayjs().format("YYYY-MM-DD"),
                              },
                              where: {
                                taskId: task.taskId,
                                taskPerson_SINGLE: {
                                  personId: task.taskPerson.personId,
                                },
                                taskOwner: { userId: loggedUserId },
                              },
                            },
                          }).then(() => {
                            refetchTasks();
                          });
                        }
                      }}
                    >
                      {status.name.replace(/_/g, " ")}
                    </Tag.CheckableTag>
                  ))}
                </div>
              </List.Item>
            )}
          ></List>
        </Card>
        <Card title="Tasks Progress" className="card chart">
          {tasksLoading ? (
            <Spin></Spin>
          ) : (
            <div className="donut">
              <DoughnutChart dataList={dataChart} colors={backgroundColors} />
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
