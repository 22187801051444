import { useMutation } from "@apollo/client";
import { Form, Input, Select, Button, Card, Modal } from "antd";
import dayjs from "dayjs";
import {
  CreateTasksDocument,
  SortDirection,
  useGetPeopleQuery,
  useGetTaskStatusQuery,
} from "../../../generated/graphql";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";

interface TaskFormProps {
  visible: boolean;
  loggedUserId: string;
  onCancel: (param?: any) => void;
}

const TaskForm: React.FC<TaskFormProps> = ({
  visible,
  loggedUserId,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [personValues, setPersonValues] = useState<any>();
  const [createTasks] = useMutation(CreateTasksDocument);
  const { data: taskStatus } = useGetTaskStatusQuery();
  const taskStatuses = taskStatus?.__type?.enumValues;
  const { data: people } = useGetPeopleQuery({
    variables: {
      where: {
        personCreatedBy: {
          userId: loggedUserId,
        },
      },
      options: { sort: [{ personName: SortDirection.Asc }] },
    },
  });

  const handleFormSubmit = async (values: any) => {
    const personId = personValues?.key;
    const personName = personValues?.value;

    try {
      const createData = {
        ...values,
        taskId: uuidv4(),
        taskCreatedAt: dayjs().format("YYYY-MM-DD"),
        taskUpdatedAt: dayjs().format("YYYY-MM-DD"),
        taskDueDate: dayjs().format("YYYY-MM-DD"),
        taskOwner: {
          connect: {
            where: {
              node: {
                userId: loggedUserId,
              },
            },
          },
        },
        taskPerson: {
          connect: {
            where: {
              node: {
                personId: personId,
                personName: personName,
              },
            },
          },
        },
      };

      await createTasks({
        variables: { input: { ...createData } },
      }).then((res) => {
        if (!res.errors) {
          form.resetFields();
          onCancel(true);
        }
      });
    } catch (error) {
      console.error("Error creating a task", error);
    }
  };

  return (
    <Modal
      title="Add Task"
      open={visible}
      footer={null}
      onCancel={() => onCancel(false)}
    >
      <Card style={{ maxWidth: "800px", width: "100%" }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
          initialValues={{
            taskName: "",
            taskDescription: "",
            taskStatus: "PENDING",
          }}
        >
          <Form.Item
            label="Person"
            name="taskPerson"
            rules={[{ required: true, message: "Select a person" }]}
          >
            <Select
              showSearch
              placeholder="Select people"
              onSelect={(value, option) => setPersonValues(option)}
              options={people?.people?.map((person) => ({
                key: person.personId,
                value: person.personName,
                label: person.personName,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Task Name"
            name="taskName"
            rules={[{ required: true, message: "Task Name is required" }]}
          >
            <Input placeholder="Enter task name" />
          </Form.Item>
          <Form.Item label="Task Description" name="taskDescription">
            <Input.TextArea placeholder="Enter task description" />
          </Form.Item>
          <Form.Item
            label="Task Status"
            name="taskStatus"
            rules={[{ required: true, message: "Task Status is required" }]}
          >
            <Select placeholder="Select a task status">
              {taskStatuses &&
                taskStatuses.map((status: any) => (
                  <Select.Option key={status.name} value={status.name}>
                    {status.name.replace(/_/g, " ")}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>{" "}
    </Modal>
  );
};

export default TaskForm;
