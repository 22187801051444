import React, { useState, useEffect } from "react";
import { AgCharts } from "ag-charts-react";

const DoughnutChart = ({ dataList, colors }) => {
  const [legendPosition, setLegendPosition] = useState("right");

  useEffect(() => {
    const updateLegendPosition = () => {
      if (window.matchMedia("(max-width: 1024px)").matches) {
        setLegendPosition("bottom");
      } else {
        setLegendPosition("right");
      }
    };
    updateLegendPosition();
    window.addEventListener("resize", updateLegendPosition);
    return () => window.removeEventListener("resize", updateLegendPosition);
  }, []);

  const options = {
    autosize: true,
    data: [...dataList],
    series: [
      {
        type: "pie",
        angleKey: "amount",
        labelKey: "label",
        legendItemKey: "label",
        innerRadiusRatio: 0.7,
        fills: [...colors],
        strokes: ["#ffffff"],
      },
    ],
    title: {
      text: "",
      fontSize: 0,
    },
    legend: {
      enabled: true,
      position: legendPosition,
    },
  };

  return <AgCharts options={options} />;
};

export default DoughnutChart;
