import React from "react";
import { EmailInterface } from "../../../types/types";
import { Button, Input, Popconfirm, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import "../../../../style/emailsInput.scss";

const EmailInputs = ({ action, emails, setEmails, emailTypes }: any) => {
  const handleEmailChange = (
    id: string,
    field: keyof EmailInterface,
    value: any
  ) => {
    const modifiedValue = (email: any) => {
      if (!email.newEmail) {
        if (field === "modified" && value === "delete") {
          return "delete";
        } else {
          return "update";
        }
      } else if (email.newEmail && field === "modified" && value === "delete") {
        return "delete";
      }
    };
    setEmails((prevEmails: any) =>
      prevEmails.map((email: EmailInterface) =>
        email.emailId === id
          ? {
              ...email,
              [field]: value,
              modified: modifiedValue(email),
            }
          : email
      )
    );
  };

  const SelectTypes = (email: { emailType: unknown; emailId: string }) => {
    return (
      <Select
        className="email-select"
        placeholder="Select Type"
        value={
          action === "update" && email.emailType ? email.emailType : undefined
        }
        options={
          emailTypes.__type?.enumValues
            ? emailTypes.__type?.enumValues.map((media: any) => ({
                value: media.name,
                label: media.name,
              }))
            : []
        }
        onChange={(value) => {
          handleEmailChange(email.emailId, "emailType", value);
        }}
      ></Select>
    );
  };

  return (
    emailTypes?.__type?.enumValues &&
    emails.filter((email: EmailInterface) => email?.modified !== "delete")
      .length > 0 &&
    emails
      .filter((email: EmailInterface) => email?.modified !== "delete")
      .map((email: EmailInterface) => (
        <div className="email-inputs">
          <span className="email-input-container" key={email.emailId}>
            <div className="responsive-select-display">
              <SelectTypes
                emailType={email.emailType}
                emailId={email.emailId}
              />
            </div>
            <Input
              className="email-input"
              placeholder="Enter email address"
              defaultValue={action === "update" ? email.emailAddress : ""}
              onChange={(e) => {
                handleEmailChange(
                  email.emailId,
                  "emailAddress",
                  e.target.value
                );
              }}
              addonBefore={
                <SelectTypes
                  emailType={email.emailType}
                  emailId={email.emailId}
                />
              }
            />
          </span>
          <Popconfirm
            title="Are you sure you want to delete this email address?"
            onConfirm={() =>
              handleEmailChange(email.emailId, "modified", "delete")
            }
            okText="Yes"
            cancelText="No"
          >
            <Button type="default" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      ))
  );
};

export default EmailInputs;
