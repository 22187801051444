import React from "react";
import { Button, Checkbox, Menu } from "antd";
import {
  BlockOutlined,
  TeamOutlined,
  CheckSquareOutlined,
  PlusCircleOutlined,
  UserOutlined,
  PoweroffOutlined,
  EditOutlined,
  EllipsisOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "../../style/responsive-navbar.scss";
import { removeCookie } from "../hooks/cookie";

interface ResponsiveNavbarProps {
  selectedNavKey: string;
  setSelectedNavKey: (key: string) => void;
  setSelectedSpheres: (spheres: any[]) => void;
  setIsSphereFormVisible: (visible: boolean) => void;
  setIsRelationFormVisible: (visible: boolean) => void;
  setIsTaskFormVisible: (visible: boolean) => void;
  userSpheres: any[];
  hoveredSphereId?: string | boolean | null;
  setHoveredSphereId: (id: string | boolean) => void;
  handleItemClick: (e: any, item: any) => void;
  selectedSpheres: any[];
  handleCheckboxChange: (checked: boolean, sphere: any) => void;
  setAction: React.Dispatch<React.SetStateAction<"create" | "edit" | null>>;
  setSphereToUpdate: (sphere: any) => void;
}

const ResponsiveNavbar: React.FC<ResponsiveNavbarProps> = ({
  selectedNavKey,
  setSelectedNavKey,
  setSelectedSpheres,
  setIsSphereFormVisible,
  setIsRelationFormVisible,
  setIsTaskFormVisible,
  userSpheres,
  hoveredSphereId,
  setHoveredSphereId,
  handleItemClick,
  selectedSpheres,
  handleCheckboxChange,
  setAction,
  setSphereToUpdate,
}) => {
  const handleLogout = () => {
    removeCookie("user");
    removeCookie("token");
    window.location.href = "/login";
  };
  return (
    <div className="bottom-navbar">
      <Menu
        mode="horizontal"
        theme="dark"
        selectedKeys={[selectedNavKey]}
        className="bottom-nav-menu"
      >
        <Menu.Item
          key="dashboard"
          title=""
          onClick={() => setSelectedNavKey("dashboard")}
        >
          <Link to="/">
            <HomeOutlined style={{ fontSize: "20px" }} />
          </Link>
        </Menu.Item>
        <Menu.SubMenu
          key="spheres-header"
          title={<BlockOutlined style={{ fontSize: "20px" }} />}
          className="spheres-header"
        >
          <Menu.Item
            key="allpeople"
            icon={<TeamOutlined />}
            onClick={() => {
              setSelectedSpheres([]);
              setSelectedNavKey("allpeople");
            }}
          >
            <Link to="/people-list" state={{ selectedSpheres: [] }}>
              All People
            </Link>
          </Menu.Item>
          {userSpheres
            .slice()
            .sort((a, b) => a.sphereName.localeCompare(b.sphereName))
            .map((item) => (
              <Menu.Item
                key={item.sphereId}
                onMouseEnter={() => setHoveredSphereId(item.sphereId)}
                onMouseLeave={() => setHoveredSphereId(false)}
                style={{ paddingLeft: "8px" }}
              >
                <div
                  className="navbar-item"
                  onClick={(e) => {
                    handleItemClick(e, item);
                    setSelectedNavKey(item.sphereId);
                  }}
                >
                  <Checkbox
                    checked={selectedSpheres.some(
                      (sphere) => sphere.sphereId === item.sphereId
                    )}
                    onChange={(e) =>
                      handleCheckboxChange(e.target.checked, {
                        sphereId: item.sphereId,
                        sphereName: item.sphereName,
                        sphereColor: item.sphereColor
                          ? item.sphereColor
                          : "#1677ff",
                      })
                    }
                    style={{ color: "white", padding: "8px 0px" }}
                  ></Checkbox>
                  <p
                    style={{
                      width: "160px",
                      wordBreak: "break-word",
                      whiteSpace: "wrap",
                      lineHeight: "1.5",
                      paddingLeft: "8px",
                    }}
                  >
                    {item.sphereName}
                  </p>
                  <Button
                    type="link"
                    onClick={() => {
                      setAction("edit");
                      setIsSphereFormVisible(true);
                      setSphereToUpdate({
                        sphereId: item.sphereId,
                        sphereName: item.sphereName,
                        sphereColor: item.sphereColor,
                      });
                    }}
                  >
                    <EditOutlined
                      style={{
                        cursor: "pointer",
                        color: "white",
                        backgroundColor: "#3a86ff",
                        padding: "8px",
                        borderRadius: "4px",
                      }}
                    />
                  </Button>
                </div>
              </Menu.Item>
            ))}
        </Menu.SubMenu>
        <Menu.SubMenu
          key="add"
          title={<PlusCircleOutlined style={{ fontSize: "40px" }} />}
        >
          <Menu.Item
            key="add-sphere"
            onClick={() => {
              setIsSphereFormVisible(true);
              setSelectedNavKey("add-sphere");
            }}
          >
            Add Sphere
          </Menu.Item>
          <Menu.Item
            key="add-person"
            onClick={() => {
              setSelectedNavKey("add-person");
            }}
          >
            <Link to="/person-form/create">Add Person</Link>
          </Menu.Item>
          <Menu.Item
            key="add-relationship"
            onClick={() => {
              setIsRelationFormVisible(true);
              setSelectedNavKey("add-relationship");
            }}
          >
            Add Relationship
          </Menu.Item>
          <Menu.Item
            key="add-task"
            onClick={() => {
              setIsTaskFormVisible(true);
              setSelectedNavKey("add-task");
            }}
          >
            Add Task
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item
          key="tasks"
          title=""
          onClick={() => setSelectedNavKey("tasks")}
        >
          <Link to="/tasks">
            <CheckSquareOutlined style={{ fontSize: "20px" }} />
          </Link>
        </Menu.Item>
        <Menu.SubMenu
          key="user-header"
          title={<EllipsisOutlined style={{ fontSize: "20px" }} />}
        >
          <Menu.Item
            key="userAccount"
            title=""
            onClick={() => setSelectedNavKey("userAccount")}
          >
            <Link to="/my-account">
              <UserOutlined style={{ fontSize: "20px" }} /> My Account
            </Link>
          </Menu.Item>
          <Menu.Item key="logout" title="">
            <span onClick={() => handleLogout()}>
              <PoweroffOutlined style={{ fontSize: "20px" }} /> Logout
            </span>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </div>
  );
};

export default ResponsiveNavbar;
