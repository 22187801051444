import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Form, Button, Modal, Select } from "antd";
import {
  SortDirection,
  CreateSpheresDocument,
  useGetPeopleQuery,
  useGetRelationshipTypeQuery,
  CreatePersonRelationsDocument,
} from "../../../../generated/graphql";
import { v4 as uuidv4 } from "uuid";
import { getCookie } from "../../../hooks/cookie";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";

interface RelationFormProps {
  visible: boolean;
  loggedUserId: string;
  onCancel: (param?: any) => void;
}

const RelationForm: React.FC<RelationFormProps> = ({
  visible,
  loggedUserId,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [createPersonRelations] = useMutation(CreatePersonRelationsDocument);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {
    data: relationshipType,
    loading: relationshipTypeLoading,
    error: relationshipTypeError,
  } = useGetRelationshipTypeQuery();
  const relationshipTypes = relationshipType?.__type?.enumValues;
  const {
    loading,
    error,
    data: people,
    refetch,
  } = useGetPeopleQuery({
    variables: {
      where: {
        personCreatedBy: {
          userId: loggedUserId,
        },
      },
      options: { sort: [{ personName: SortDirection.Asc }] },
    },
  });

  const useHandleActionSphere = async () => {
    try {
      const values = await form.validateFields();
      const dateTime = new Date().toISOString();
      const connectPeople = () => {
        const list = [...values.toPerson];
        if (list.length) {
          return list.map((name) => ({
            where: {
              node: {
                personName: name,
                personCreatedBy: { userId: loggedUserId },
              },
            },
          }));
        }
      };

      const newRelation = await createPersonRelations({
        variables: {
          input: {
            relationId: uuidv4(),
            relationCreatedAt: dateTime,
            relationUpdatedAt: dateTime,
            relationType: values.relationshipStatus,
            fromPerson: {
              connect: {
                where: {
                  node: {
                    personName: values.fromPerson,
                    personCreatedBy: { userId: loggedUserId },
                  },
                },
              },
            },
            toPerson: {
              connect: connectPeople(),
            },
          },
        },
      });
      if (
        newRelation.data.createPersonRelations.personRelations[0].relationId
      ) {
        form.resetFields();
        onCancel(true);
      }
    } catch (error: any) {
      console.error("Failed to create relationship: ", error);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={() => onCancel(false)}
      footer={null}
      title="Create relationships"
    >
      {!relationshipTypeLoading && !relationshipTypeError && (
        <Form
          form={form}
          layout="vertical"
          // initialValues={{  }}
        >
          <Form.Item
            label=" "
            name="fromPerson"
            rules={[{ required: true, message: "Select a person" }]}
          >
            <Select
              showSearch
              placeholder="Select a person"
              onChange={() => setErrorMessage("")}
              options={people?.people?.map((person) => ({
                key: person.personId,
                value: person.personName,
                label: person.personName,
              }))}
            />
          </Form.Item>
          <div style={{ textAlign: "center" }}>
            <ArrowDownOutlined />
            <ArrowUpOutlined />
          </div>
          <Form.Item
            label=" "
            name="toPerson"
            rules={[{ required: true, message: "Select a person" }]}
          >
            <Select
              showSearch
              placeholder="Select people"
              onChange={() => setErrorMessage("")}
              options={people?.people?.map((person) => ({
                key: person.personId,
                value: person.personName,
                label: person.personName,
              }))}
              mode="multiple"
            />
          </Form.Item>
          <Form.Item
            label="Type of Relationship"
            name="relationshipStatus"
            rules={[
              { required: true, message: "Type of Relationship is required" },
            ]}
          >
            <Select
              placeholder="Select a relationship type"
              onChange={() => setErrorMessage("")}
            >
              {relationshipTypes &&
                relationshipTypes?.map((status: any) => (
                  <Select.Option key={status.name} value={status.name}>
                    {status.name.replace(/_/g, " ")}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Button type="primary" onClick={useHandleActionSphere}>
                  Create
                </Button>
                <Button
                  onClick={() => onCancel(true)}
                  style={{ margin: "auto 8px" }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form.Item>
          {errorMessage && (
            <p
              style={{ textAlign: "center", color: "red", fontWeight: "bold" }}
            >
              {errorMessage}
            </p>
          )}
        </Form>
      )}
    </Modal>
  );
};

export default RelationForm;
