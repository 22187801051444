import {
  EditOutlined,
  MailOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Form, Input, List } from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useEffect } from "react";
import {
  UpdateEmailsDocument,
  UpdatePeopleDocument,
  useGetEmailTypesQuery,
} from "../../../../generated/graphql";
import { useMutation } from "@apollo/client";
import { EmailInterface } from "../../../types/types";
import { v4 as uuidv4 } from "uuid";
import EmailInputs from "../form-components/EmailInputs";
import { useParams } from "react-router-dom";

interface PersonEmailsProps {
  personId: string;
  personPrimaryEmail: string;
  personEmails?: EmailInterface[];
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  newPerson?: any;
  setNewPerson?: React.Dispatch<React.SetStateAction<any>>;
}
const PersonEmails: React.FC<PersonEmailsProps> = ({
  personId,
  personPrimaryEmail,
  personEmails,
  setRefetch,
  newPerson,
  setNewPerson,
}) => {
  const { action } = useParams();
  const [editMode, setEditMode] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [emails, setEmails] = React.useState<any>([]);
  const { data: emailTypes } = useGetEmailTypesQuery();
  const [updateEmail] = useMutation(UpdateEmailsDocument);
  const [updatePerson] = useMutation(UpdatePeopleDocument);

  useEffect(() => {
    if (personEmails) {
      setEmails(
        personEmails.map((email: EmailInterface) => ({
          emailId: email.emailId,
          emailType: email.emailType,
          emailAddress: email.emailAddress,
          newPlatform: false,
        }))
      );
    }
    if (!editMode && !personId.length) {
      setEditMode(true);
    }
  }, [editMode, personEmails, personId]);

  const handleSubmit = async (values: any) => {
    const dateTime = new Date().toISOString();
    const { ...rest } = values;
    const emailCleanList = emails.filter(
      (email: EmailInterface) =>
        email.emailAddress.length && email.emailType.length
    );
    try {
      const handleCreateEmails = () => {
        const list = [...emailCleanList];
        if (list.length && list[0].emailAddress) {
          const newEmails = list.filter((email) => email.newEmail);

          if (newEmails.length) {
            return newEmails.map((email) => ({
              node: {
                emailId: email.emailId,
                emailAddress: email.emailAddress,
                emailType: email.emailType,
                emailOwner: {
                  connect: { where: { node: { personId: personId } } },
                },
              },
            }));
          }
        }
      };
      const handleUpdateEmails = async () => {
        try {
          const list = [...emailCleanList];
          if (list.length && list[0].emailAddress && personId) {
            const modifiedEmails = list.filter(
              (email) => !email.newEmail && email.modified === "update"
            );
            if (modifiedEmails.length) {
              await Promise.all(
                modifiedEmails.map((email) =>
                  updateEmail({
                    variables: {
                      where: {
                        emailId: email.emailId,
                        emailOwner: { personId: personId },
                      },
                      update: {
                        emailAddress: email.emailAddress,
                        emailType: email.emailType,
                      },
                    },
                  })
                )
              );
              setEditMode(false);
              setRefetch(true);
            } else {
              setEditMode(false);
              setRefetch(true);
            }
          } else {
            setEditMode(false);
            setRefetch(true);
          }
        } catch (error: any) {
          console.error("[Failed to update Email]", error);
          if (error.graphQLErrors) {
            console.error(
              "[PERSON FORM - GraphQL Error: ]" +
                error.graphQLErrors.map((e: any) => e.message).join(", ")
            );
          }
          if (error.networkError) {
            console.error(
              "[PERSON FORM - Network Error: ]" + error.networkError.message
            );
          }
        }
      };
      const handleDeleteEmails = () => {
        const list = [...emailCleanList];
        if (list.length && list[0].emailAddress && personId) {
          const deletedEmails = list.filter(
            (email) => !email.newEmail && email.modified === "delete"
          );
          if (deletedEmails.length) {
          }

          return deletedEmails.map((email) => ({
            where: {
              node: {
                emailId: email.emailId,
                emailOwner: { personId: personId },
              },
            },
          }));
        }
      };

      const response = await updatePerson({
        variables: {
          update: {
            ...rest,
            personEmails: {
              create: handleCreateEmails(),
              delete: handleDeleteEmails(),
            },
            personUpdatedAt: dateTime,
          },
          where: { personId: personId },
        },
      });

      if (response.errors) {
        setErrorMessage("ERROR TO UPDATE PERSON" + response.errors[0].message);
      } else {
        handleUpdateEmails();
      }
    } catch (e) {
      console.error("ERROR UPDATING PERSON'S EMAILS: " + e);
    }
  };

  return (
    <Card
      className="card-children"
      title={
        <>
          <MailOutlined /> Emails
        </>
      }
      extra={
        <Button type="link" onClick={() => setEditMode(!editMode)}>
          {editMode ? personId !== "" ? "Cancel" : "" : <EditOutlined />}
        </Button>
      }
    >
      {editMode ? (
        <Form
          layout="vertical"
          initialValues={{
            personPrimaryEmail: personPrimaryEmail,
          }}
          onFinish={handleSubmit}
        >
          <FormItem
            label="Primary Email"
            name="personPrimaryEmail"
            rules={[{ required: true, message: "Please enter primary email" }]}
          >
            <Input
              onChange={(e) => {
                if (action === "create" && setNewPerson) {
                  setNewPerson({
                    ...newPerson,
                    personPrimaryEmail: e.target.value,
                  });
                }
              }}
            />
          </FormItem>
          {action !== "create" && (
            <>
              <FormItem label="Additional Emails">
                <EmailInputs
                  action="update"
                  emails={emails}
                  setEmails={setEmails}
                  emailTypes={emailTypes}
                />
              </FormItem>
              <Button
                type="primary"
                style={{ margin: "16px 0px" }}
                onClick={() =>
                  setEmails([
                    ...emails,
                    {
                      emailId: uuidv4(),
                      emailAddress: "",
                      emailType: "",
                      newEmail: true,
                    },
                  ])
                }
              >
                <PlusCircleOutlined /> Add Email Address
              </Button>
            </>
          )}
          <Form.Item>
            {action === "create" ? (
              ""
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Save
              </Button>
            )}
            {errorMessage && (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                {errorMessage}
              </p>
            )}
          </Form.Item>
        </Form>
      ) : (
        <>
          {personPrimaryEmail && <p>PRIMARY {personPrimaryEmail}</p>}
          {personEmails && personEmails.length ? (
            <List
              className="list"
              itemLayout="horizontal"
              dataSource={personEmails}
              renderItem={(email: EmailInterface) => (
                <List.Item>
                  {email.emailType} {email.emailAddress}
                </List.Item>
              )}
            />
          ) : (
            ""
          )}
        </>
      )}
    </Card>
  );
};

export default PersonEmails;
