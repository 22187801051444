import {
  BlockOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Divider, Form, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelectedSpheres } from "../../../../contexts/SelectedSpheresContext";
import { tagColor } from "../../../hooks/visual";
// import FormItem from "antd/es/form/FormItem";
import {
  //   CreateSpheresDocument,
  UpdatePeopleDocument,
  useGetSpheresQuery,
} from "../../../../generated/graphql";
// import { v4 as uuidv4 } from "uuid";
import { useMutation } from "@apollo/client";

interface PersonSpheresProps {
  userId: string;
  personId: string;
  personName?: string;
  personSpheres: any[];
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

const PersonSpheres: React.FC<PersonSpheresProps> = ({
  userId,
  personId,
  personName,
  personSpheres,
  setRefetch,
}) => {
  const navigate = useNavigate();
  const { action } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedSphereIds, setSelectedSphereIds] = useState<string[]>([]);
  const [selectedSphereNames, setSelectedSphereNames] = useState<string[]>([]);
  const [searchSphere, setSearchSphere] = useState<string | null>(null);
  const { addSphere } = useSelectedSpheres();
  //   const [createSphere] = useMutation(CreateSpheresDocument);
  const { data: spheres, refetch: refetchSpheres } = useGetSpheresQuery({
    variables: { where: { sphereUserOwner: { userId: userId } } },
  });
  const [updatePerson] = useMutation(UpdatePeopleDocument);

  useEffect(() => {
    if (personSpheres.length && spheres?.spheres?.length) {
      setSelectedSphereIds(personSpheres.map((sphere) => sphere.sphereId));
      setSelectedSphereNames(personSpheres.map((sphere) => sphere.sphereName));
    }
  }, [personSpheres, spheres?.spheres, editMode]);

  useEffect(() => {
    if (action === "create" && personId.length && personName?.length) {
      handleSubmit({});
    }
  }, [personId, personName, action]);

  //   const handleCreateSphere = async () => {
  //     console.log("searchSphere", searchSphere);
  //     if (searchSphere?.length) {
  //       try {
  //         const newId = uuidv4();
  //         const dateTime = new Date().toISOString();
  //         const { data } = await createSphere({
  //           variables: {
  //             input: [
  //               {
  //                 sphereId: newId,
  //                 sphereName: searchSphere,
  //                 sphereColor: "#1677ff",
  //                 sphereCreatedAt: dateTime,
  //                 sphereUpdatedAt: dateTime,
  //                 sphereUserOwnerId: userId,
  //                 sphereUserOwner: {
  //                   connect: {
  //                     where: {
  //                       node: {
  //                         userId: userId,
  //                       },
  //                     },
  //                   },
  //                 },
  //               },
  //             ],
  //             where: { userId: userId },
  //           },
  //         });

  //         if (data) {
  //           console.log("NEW SPHERE: ", data);
  //           setSelectedSphereIds([...selectedSphereIds, newId]);
  //           setSelectedSphereNames([...selectedSphereNames, searchSphere]);
  //           refetchSpheres();
  //         } else {
  //           setErrorMessage("Failed to create Sphere");
  //         }
  //       } catch (error: any) {
  //         console.error("[Failed to create Sphere]", error);
  //         if (error.graphQLErrors) {
  //           console.error(
  //             "[PERSON FORM - GraphQL Error: ]" +
  //               error.graphQLErrors.map((e: any) => e.message).join(", ")
  //           );
  //         }
  //         if (error.networkError) {
  //           console.error(
  //             "[PERSON FORM - Network Error: ]" + error.networkError.message
  //           );
  //         }
  //       }
  //     }
  //   };

  const handleSubmit = async (values: any) => {
    try {
      const dateTime = new Date().toISOString();
      const originalSpheres = personSpheres.map((sphere) => sphere.sphereId);

      const connectSpheres = () => {
        const spheres = [...selectedSphereIds];
        if (spheres.length) {
          return spheres.map((id) => ({
            where: { node: { sphereId: id } },
          }));
        }
      };
      const disconnectSpheres = () => {
        if (originalSpheres.length && personId) {
          const spheres = [...originalSpheres];
          const deletedSpheres = spheres.filter(
            (sphereId) => !selectedSphereIds.includes(sphereId)
          );
          if (deletedSpheres.length) {
            return deletedSpheres.map((id) => ({
              where: {
                node: {
                  sphereId: id,
                  spherePersons_SINGLE: {
                    personId: personId,
                  },
                },
              },
            }));
          }
        }
      };

      const response = await updatePerson({
        variables: {
          update: {
            personSpheres: {
              connect: connectSpheres(),
              disconnect: disconnectSpheres(),
            },
            personUpdatedAt: dateTime,
          },
          where: { personId: personId },
        },
      });

      if (response.errors) {
        setErrorMessage("ERROR TO UPDATE PERSON" + response.errors[0].message);
      } else {
        setSelectedSphereIds([]);
        setSelectedSphereNames([]);
        if (action === "create") {
          navigate(`/person/${personName}`, { state: { personId: personId } });
        } else {
          setEditMode(false);
          setRefetch(true);
        }
      }
    } catch (error) {
      console.error("[Failed to update Spheres]", error);
    }
  };

  return (
    <Card
      title={
        <>
          <BlockOutlined /> Spheres
        </>
      }
      className="sphere-card"
      extra={
        <Button type="link" onClick={() => setEditMode(!editMode)}>
          {editMode ? (
            "Cancel"
          ) : personId !== "" ? (
            <EditOutlined />
          ) : (
            <>
              <PlusCircleOutlined /> Add
            </>
          )}
        </Button>
      }
    >
      {editMode ? (
        <Form layout="vertical" onFinish={handleSubmit}>
          {spheres?.spheres.length ? (
            <Select
              mode="multiple"
              value={selectedSphereNames}
              allowClear={true}
              onChange={(value, option) => {
                setSelectedSphereNames(value);
                if (Array.isArray(option)) {
                  setSelectedSphereIds(option.map((o: any) => o.key));
                }
              }}
              style={{ width: "100%" }}
              placeholder="Select spheres"
              onSearch={(value) => setSearchSphere(value.trim())}
              dropdownRender={(menu) => {
                // if (
                //   searchSphere &&
                //   !spheres?.spheres.filter(
                //     (sphere) => sphere.sphereName === searchSphere
                //   ).length
                // ) {
                //   return (
                //     <>
                //       {menu}
                //       <Divider style={{ margin: "8px 0" }} />
                //       <Space style={{ padding: "0 8px 4px" }}>
                //         <Button
                //           type="primary"
                //           onClick={() => {
                //             console.log("clicked");
                //             handleCreateSphere();
                //           }}
                //           style={{ width: "100%", textAlign: "left" }}
                //         >
                //           Create Sphere
                //         </Button>
                //       </Space>
                //     </>
                //   );
                // } else {
                return menu;
                // }
              }}
              options={spheres?.spheres.map(
                (sphere: { sphereId: string; sphereName: string }) => ({
                  key: sphere.sphereId,
                  value: sphere.sphereName,
                  label: sphere.sphereName,
                })
              )}
            />
          ) : (
            "No spheres available"
          )}
          <Form.Item style={{ marginTop: "16px" }}>
            {action === "create" ? (
              ""
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Save
              </Button>
            )}
            {errorMessage && (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                {errorMessage}
              </p>
            )}
          </Form.Item>
        </Form>
      ) : (
        <div className="sphere-list-item">
          {personSpheres?.length
            ? personSpheres.map((sphere) => (
                <Link
                  to={"/people-list"}
                  key={sphere.sphereId}
                  onClick={() => addSphere(sphere)}
                >
                  <span
                    className="tag"
                    style={tagColor(
                      sphere.sphereColor ? sphere.sphereColor : "#1677ff"
                    )}
                  >
                    {sphere.sphereName}
                  </span>
                </Link>
              ))
            : "No Spheres"}
        </div>
      )}
    </Card>
  );
};

export default PersonSpheres;
