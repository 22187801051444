import { Button, Card, Form, List, Select } from "antd";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ApiOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  CreatePersonRelationsDocument,
  SortDirection,
  useGetPeopleQuery,
  useGetRelationshipTypeQuery,
} from "../../../../generated/graphql";
import { v4 as uuidv4 } from "uuid";
import { useMutation } from "@apollo/client";

interface PersonRelationshipsProps {
  loggedUserId: string;
  personId: string;
  personName?: string;
  personRelationships?: any[] | undefined;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

const PersonRelationships: React.FC<PersonRelationshipsProps> = ({
  loggedUserId,
  personId,
  personName,
  personRelationships,
  setRefetch,
}) => {
  const { action } = useParams();
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [createPersonRelations] = useMutation(CreatePersonRelationsDocument);
  const {
    data: relationshipType,
    loading: relationshipTypeLoading,
    error: relationshipTypeError,
  } = useGetRelationshipTypeQuery();
  const relationshipTypes = relationshipType?.__type?.enumValues;

  const displayRelationList = () => {
    if (personRelationships?.length) {
      const groupedRelations = personRelationships.reduce(
        (acc: { type: string; peopleList: any[] }[], relation) => {
          // Destructure relevant properties
          const { relationType, toPerson, fromPerson } = relation;

          // Check if relationType group already exists
          const existingGroup = acc.find(
            (group) => group.type === relationType
          );

          // Determine which value to add (fromPerson or toPerson)
          const valueToAdd = toPerson.some(
            (person: { personId: string }) => person.personId === personId
          )
            ? fromPerson
            : toPerson;

          if (existingGroup) {
            // Add to the existing group and flatten the array
            existingGroup.peopleList.push(
              ...(Array.isArray(valueToAdd) ? valueToAdd : [valueToAdd])
            );
          } else {
            // Create a new group for this relationType
            acc.push({
              type: relationType,
              peopleList: Array.isArray(valueToAdd)
                ? [...valueToAdd]
                : [valueToAdd],
            });
          }

          return acc;
        },
        []
      );

      // Remove duplicates from the peopleList of each group
      const deduplicatedRelations = groupedRelations.map((group) => {
        const uniquePeople = Array.from(
          new Map(
            group.peopleList.map((person) => [person.personId, person])
          ).values()
        );

        return {
          ...group,
          peopleList: uniquePeople,
        };
      });

      return deduplicatedRelations;
    }

    return [];
  };

  const { data: people } = useGetPeopleQuery({
    variables: {
      where: {
        personCreatedBy: {
          userId: loggedUserId,
        },
      },
      options: { sort: [{ personName: SortDirection.Asc }] },
    },
  });

  const useHandleActionSphere = async () => {
    try {
      const values = await form.validateFields();
      const dateTime = new Date().toISOString();
      const connectPeople = () => {
        const list = [...values.toPerson];
        if (list.length) {
          return list.map((name) => ({
            where: {
              node: {
                personName: name,
                personCreatedBy: { userId: loggedUserId },
              },
            },
          }));
        }
      };

      const newRelation = await createPersonRelations({
        variables: {
          input: {
            relationId: uuidv4(),
            relationCreatedAt: dateTime,
            relationUpdatedAt: dateTime,
            relationType: values.relationshipStatus,
            fromPerson: {
              connect: {
                where: {
                  node: {
                    personId: personId,
                    personName: personName,
                    personCreatedBy: { userId: loggedUserId },
                  },
                },
              },
            },
            toPerson: {
              connect: connectPeople(),
            },
          },
        },
      });
      if (
        newRelation.data.createPersonRelations.personRelations[0].relationId
      ) {
        form.resetFields();
        setEditMode(false);
        setRefetch(true);
      }
    } catch (error: any) {
      console.error("Failed to create relationship: ", error);
    }
  };

  return (
    <Card
      title={
        <>
          <ApiOutlined /> Relationships
        </>
      }
      className="relationship-card card-container"
      extra={
        action !== "create" && (
          <Button type="link" onClick={() => setEditMode(!editMode)}>
            {editMode ? (
              "Cancel"
            ) : (
              <>
                <PlusCircleOutlined /> Add
              </>
            )}
          </Button>
        )
      }
    >
      {editMode && !relationshipTypeLoading && !relationshipTypeError && (
        <Form form={form} layout="vertical">
          <Form.Item
            label={personName + " knows"}
            name="toPerson"
            rules={[{ required: true, message: "Select a person" }]}
          >
            <Select
              showSearch
              placeholder="Select people"
              onChange={() => setErrorMessage("")}
              options={people?.people
                ?.filter((person) => person.personName !== personName)
                .map((person) => ({
                  key: person.personId,
                  value: person.personName,
                  label: person.personName,
                }))}
              mode="multiple"
            />
          </Form.Item>
          <Form.Item
            label="Type of Relationship"
            name="relationshipStatus"
            rules={[
              { required: true, message: "Type of Relationship is required" },
            ]}
          >
            <Select
              placeholder="Select a relationship type"
              onChange={() => setErrorMessage("")}
            >
              {relationshipTypes &&
                relationshipTypes?.map((status: any) => (
                  <Select.Option key={status.name} value={status.name}>
                    {status.name.replace(/_/g, " ")}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Button type="primary" onClick={useHandleActionSphere}>
                  Save
                </Button>
              </div>
            </div>
          </Form.Item>
          {errorMessage && (
            <p
              style={{ textAlign: "center", color: "red", fontWeight: "bold" }}
            >
              {errorMessage}
            </p>
          )}
        </Form>
      )}
      <ul className="relationship-list">
        {displayRelationList().length
          ? displayRelationList().map((relation) => (
              <li className="relationship-item">
                <Card>
                  <h3>{relation.type}</h3>
                  <List
                    className="list"
                    itemLayout="vertical"
                    dataSource={relation.peopleList}
                    renderItem={(person) => (
                      <List.Item>
                        <Link
                          to={`/person/${person.personName}`}
                          state={{ personId: person.personId }}
                          key={person.personId}
                        >
                          {person.personName}
                        </Link>
                      </List.Item>
                    )}
                  />
                </Card>
              </li>
            ))
          : "No Relationships"}
      </ul>
    </Card>
  );
};

export default PersonRelationships;
