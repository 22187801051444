import { Button, Card } from "antd";
import { useNavigate } from "react-router-dom";
import UserForm from "../../reusable-components/UserForm";

/**
 * UserUpdateForm component: update User account
 * Calls UserForm component with page="update" to use the form for updating User information
 */
const UserUpdateForm = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="page-title-header secondary">
        <h1>{"Update my account".toUpperCase()}</h1>
      </div>
      <Button
        onClick={() => {
          navigate("/my-account");
        }}
      >
        Back
      </Button>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Card style={{ maxWidth: "800px", width: "100%" }}>
          <UserForm page="update" />
        </Card>
      </div>
    </>
  );
};

export default UserUpdateForm;
