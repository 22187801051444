import React, { useEffect } from "react";
import { useGetUsersQuery } from "../../../generated/graphql";
import { getCookie } from "../../hooks/cookie";
import { Alert, Button, Card, Spin } from "antd";
import { displayPhoneNumber } from "../../hooks/formats";
import { useNavigate } from "react-router-dom";

/**
 * UserProfile component: display User information
 */
const UserProfile = () => {
  const navigate = useNavigate();
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;
  const { loading, error, data, refetch } = useGetUsersQuery({
    variables: {
      where: {
        userId: loggedUserId,
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [data]);

  if (loading) return <Spin tip="Loading..." />;
  if (error)
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <div className="page-title-header secondary">
        <h1>{"My Account".toUpperCase()}</h1>
      </div>
      <Button
        type="primary"
        onClick={() => {
          navigate("/my-account/update");
        }}
      >
        Update
      </Button>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Card style={{ maxWidth: "800px", width: "100%" }}>
          {data && data.users[0] && (
            <div>
              <p>Name: {data.users[0].userName}</p>
              <p>Email: {data.users[0].userEmail}</p>
              <p>
                Phone:{" "}
                {data.users[0].userPhoneNumber &&
                data.users[0].userCountryCode &&
                data.users[0].userAreaCode
                  ? displayPhoneNumber(
                      data.users[0].userPhoneNumber,
                      data.users[0].userCountryCode,
                      data.users[0].userAreaCode
                    )
                  : "N/A"}
              </p>
              <p>Birth Date: {data.users[0].userBirthDate}</p>
            </div>
          )}
        </Card>
      </div>
    </>
  );
};

export default UserProfile;
