import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DeletePeopleDocument,
  useGetPeopleQuery,
  useGetPersonRelationsQuery,
  useGetPersonTasksQuery,
} from "../../../generated/graphql";
import { getCookie } from "../../hooks/cookie";
import { Alert, Button, Card, Form, Input, Modal, Spin } from "antd";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import FormItem from "antd/es/form/FormItem";
import { useMutation } from "@apollo/client";
import "../../../style/displayPerson.scss";
import PersonInfos from "./displayPersonComponents/PersonInfos";
import PersonPhones from "./displayPersonComponents/PersonPhones";
import PersonEmails from "./displayPersonComponents/PersonEmails";
import PersonPlatforms from "./displayPersonComponents/PersonPlatforms";
import PersonSpheres from "./displayPersonComponents/PersonSpheres";
import PersonTasks from "./displayPersonComponents/PersonTasks";
import PersonRelationships from "./displayPersonComponents/PersonRelationships";

const DisplayPerson = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { name } = useParams();
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;
  const { loading, error, data, refetch } = useGetPeopleQuery({
    variables: {
      where: {
        personId: state.personId,
        personName: name,
        personCreatedBy: {
          userId: loggedUserId,
        },
      },
    },
  });
  const { data: tasks, refetch: refetchTasks } = useGetPersonTasksQuery({
    variables: {
      where: {
        taskOwner: { userId: loggedUserId },
        taskPerson_SINGLE: {
          personId: state.personId,
        },
      },
    },
  });
  const [deletePeople] = useMutation(DeletePeopleDocument);
  const person = data?.people[0];
  const [openDelete, setOpenDelete] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { data: personRelations, refetch: refetchRelationships } =
    useGetPersonRelationsQuery({
      variables: {
        where: {
          OR: [
            { fromPerson: { personId: state.personId } },
            { toPerson_SINGLE: { personId: state.personId } },
          ],
        },
      },
    });
  const [refetchData, setRefetchData] = useState(false);

  useEffect(() => {
    refetch();
    refetchTasks();
    refetchRelationships();
    setRefetchData(false);
  }, [name, refetchData]);

  if (loading) {
    return <Spin tip="Loading..." />;
  }
  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    );
  }

  return (
    <>
      <div className="page-title-header secondary">
        <h1>{person?.personName}</h1>
      </div>
      <Button onClick={() => navigate(-1)} style={{ margin: "4px" }}>
        Back
      </Button>
      <Button
        onClick={() => setOpenDelete(true)}
        danger
        style={{ margin: "4px" }}
      >
        Delete
      </Button>
      <div className="person-page">
        {person && (
          <>
            <PersonSpheres
              userId={loggedUserId}
              personId={person.personId}
              personSpheres={[...person?.personSpheres]}
              setRefetch={setRefetchData}
            />
            <Card
              title={
                <>
                  <InfoCircleOutlined /> General Information
                </>
              }
              className="card-container general-info"
            >
              <PersonInfos
                personId={person.personId}
                personName={person.personName}
                personBirthDate={person?.personBirthDate}
                personJobTitle={person?.personJobTitle}
                personDescription={person?.personDescription}
                setRefetch={setRefetchData}
              />
              <PersonPhones
                personId={person.personId}
                personPrimaryPhone={person.personPrimaryPhone}
                personPhones={[...person?.personPhones]}
                setRefetch={setRefetchData}
              />
              <PersonEmails
                personId={person.personId}
                personPrimaryEmail={person.personPrimaryEmail}
                personEmails={[...person?.personEmails]}
                setRefetch={setRefetchData}
              />
              <PersonPlatforms
                personId={person.personId}
                personPlatforms={[...person?.personPlatforms]}
                setRefetch={setRefetchData}
              />
            </Card>
            <div className="column-right">
              <PersonTasks
                loggedUserId={loggedUserId}
                personId={person.personId}
                personTasks={tasks?.tasks ? [...tasks?.tasks] : []}
                setRefetch={setRefetchData}
                refetchTasks={refetchTasks}
              />
              <PersonRelationships
                loggedUserId={loggedUserId}
                personId={person.personId}
                personName={person.personName}
                personRelationships={[
                  ...(personRelations?.personRelations || []),
                ]}
                setRefetch={setRefetchData}
              />
            </div>
          </>
        )}
      </div>
      <Modal
        title={
          <>
            <DeleteOutlined />
            Delete Person Permanently
          </>
        }
        open={openDelete}
        onCancel={() => {
          setOpenDelete(false);
        }}
        footer={null}
      >
        <Card>
          <div>
            <p>
              You are going to delete this person <b>permanently</b>.
            </p>
            <Form
              layout="vertical"
              onFinish={async (values) => {
                if (
                  name &&
                  values.personName.toUpperCase() !== name.toUpperCase()
                ) {
                  setErrorMessage("Wrong name entered. Please try again.");
                } else if (
                  name &&
                  values.personName.toUpperCase() === name.toUpperCase() &&
                  person
                ) {
                  try {
                    await deletePeople({
                      variables: {
                        where: {
                          personId: person.personId,
                          personName: name,
                        },
                      },
                    });
                    setOpenDelete(false);
                    setOpenConfirm(true);
                  } catch (error: any) {
                    setErrorMessage(error.message);
                  }
                }
              }}
            >
              <FormItem
                label={
                  <p>
                    Please enter the following name:{" "}
                    <b>{name?.toUpperCase()}</b> to confirm the permanent
                    delete:
                  </p>
                }
                name="personName"
                rules={[
                  { required: true, message: "Please enter Person's name" },
                ]}
              >
                <Input
                  placeholder="Enter Person's name"
                  onChange={() => setErrorMessage("")}
                />
              </FormItem>
              <Form.Item>
                <Button type="primary" htmlType="submit" danger>
                  CONFIRM <DeleteOutlined />
                </Button>
                {errorMessage && (
                  <p
                    style={{
                      textAlign: "center",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    {errorMessage}
                  </p>
                )}
              </Form.Item>
            </Form>
          </div>
        </Card>
      </Modal>
      <Modal
        title={name?.toUpperCase() + " has been removed from your database"}
        open={openConfirm}
        onCancel={() => {
          setOpenConfirm(false);
          window.location.href = "/";
        }}
        footer={null}
      ></Modal>
    </>
  );
};

export default DisplayPerson;
