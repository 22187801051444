import React, { useState } from "react";
import "../../../style/displayPerson.scss";
import { Button, Card, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import PersonSpheres from "./displayPersonComponents/PersonSpheres";
import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import PersonInfos from "./displayPersonComponents/PersonInfos";
import PersonPhones from "./displayPersonComponents/PersonPhones";
import PersonEmails from "./displayPersonComponents/PersonEmails";
import PersonPlatforms from "./displayPersonComponents/PersonPlatforms";
import PersonRelationships from "./displayPersonComponents/PersonRelationships";
import PersonTasks from "./displayPersonComponents/PersonTasks";
import { getCookie } from "../../hooks/cookie";
import { useMutation } from "@apollo/client";
import { CreatePeopleDocument, Person } from "../../../generated/graphql";
import { v4 as uuidv4 } from "uuid";

const CreatePersonForm = () => {
  const navigate = useNavigate();
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;
  const [refetchData, setRefetchData] = React.useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [newPerson, setNewPerson] = useState<Person>();
  const [createPerson, { loading: creating, error: createError }] =
    useMutation(CreatePeopleDocument);
  const [newPersonId, setNewPersonId] = useState("");
  const [newPersonName, setNewPersonName] = useState("");

  const createPersonForm = async () => {
    try {
      if (
        newPerson &&
        newPerson.personName &&
        newPerson.personPrimaryEmail &&
        newPerson.personPrimaryPhone
      ) {
        const dateTime = new Date().toISOString();
        const createPersonId = uuidv4();
        await createPerson({
          variables: {
            input: [
              {
                ...newPerson,
                personId: createPersonId,
                personCreatedById: loggedUserId,
                personCreatedBy: {
                  connect: { where: { node: { userId: loggedUserId } } },
                },
                personCreatedAt: dateTime,
                personUpdatedAt: dateTime,
              },
            ],
          },
        }).then(() => {
          setNewPersonId(createPersonId);
          setNewPersonName(newPerson.personName);
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <div className="page-title-header secondary">
        <h1>Add Person Form</h1>
      </div>
      <div className="below-banner">
        <Button
          onClick={() => navigate("/people-list")}
          style={{ margin: "4px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => createPersonForm()}
          style={{ margin: "4px" }}
          type="primary"
          disabled={
            !newPerson?.personName ||
            !newPerson?.personPrimaryEmail ||
            !newPerson?.personPrimaryPhone
              ? true
              : false
          }
        >
          Save Person
        </Button>
        <p>
          <span style={{ color: "red" }}>
            <b>*</b>
          </span>{" "}
          Required fields{" "}
          <Tooltip
            title={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>Person's : </div>
                <div>- Name</div>
                <div>- Primary Phone</div>
                <div>- Primary Email</div>
              </div>
            }
          >
            <QuestionCircleOutlined style={{ cursor: "pointer" }} />
          </Tooltip>
        </p>
      </div>
      <div className="person-form">
        <>
          <PersonSpheres
            userId={loggedUserId}
            personId={newPersonId}
            personName={newPersonName}
            personSpheres={[]}
            setRefetch={setRefetchData}
          />
          <Card
            title={
              <>
                <InfoCircleOutlined /> General Information
              </>
            }
            className="card-container general-info"
          >
            <PersonInfos
              personId=""
              personName=""
              setRefetch={setRefetchData}
              newPerson={newPerson}
              setNewPerson={setNewPerson}
            />
            <PersonPhones
              personId=""
              personPrimaryPhone=""
              personPhones={[]}
              setRefetch={setRefetchData}
              newPerson={newPerson}
              setNewPerson={setNewPerson}
            />
            <PersonEmails
              personId=""
              personPrimaryEmail=""
              setRefetch={setRefetchData}
              newPerson={newPerson}
              setNewPerson={setNewPerson}
            />
            <PersonPlatforms
              personId=""
              personPlatforms={[]}
              setRefetch={setRefetchData}
            />
          </Card>
          <div className="column-right">
            <PersonTasks
              loggedUserId={loggedUserId}
              personId=""
              setRefetch={setRefetchData}
            />
            <PersonRelationships
              loggedUserId={loggedUserId}
              personId=""
              setRefetch={setRefetchData}
            />
          </div>
        </>
      </div>
    </div>
  );
};

export default CreatePersonForm;
