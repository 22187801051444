import { Layout } from "antd";
import logo from "../brijmore.png";
import { getCookie } from "./hooks/cookie";
import { LogoutBtn } from "./reusable-components/LogoutBtn";

const { Header } = Layout;

const HeaderComponent = () => {
  const token = getCookie("token");

  return (
    <Header className="header">
      <img className="logo" src={logo} alt="Brijmore" />
      {token && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            zIndex: 1,
          }}
        >
          <LogoutBtn location="header" className="logout" />
        </div>
      )}
    </Header>
  );
};

export default HeaderComponent;
