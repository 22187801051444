/**
 * Navigation bar
 * @description links: All People, Spheres (query that gets all logged user's spheres)(update + delete each sphere), Add Sphere
 * You can select multiple spheres to display people from those spheres
 */

import { useEffect, useState } from "react";
import {
  TeamOutlined,
  PlusCircleOutlined,
  EditOutlined,
  UserOutlined,
  CheckSquareOutlined,
  BlockOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Menu, Button, Checkbox } from "antd";
import Sider from "antd/es/layout/Sider";
import { Link, useNavigate } from "react-router-dom";
import SphereForm from "../pages/spheres/SphereForm";
import { useGetUsersSpheresQuery } from "../../generated/graphql";
import { getCookie } from "../hooks/cookie";
import { useSelectedSpheres } from "../../contexts/SelectedSpheresContext";
import RelationForm from "../pages/people/form-components/RelationForm";
import "../../style/navbar.scss";
import TaskForm from "../pages/tasks/TaskForm";
import ResponsiveNavbar from "./ResponsiveNavbar";

const Navbar = () => {
  const { selectedSpheres, setSelectedSpheres, addSphere, removeSphere } =
    useSelectedSpheres();
  const [isSphereFormVisible, setIsSphereFormVisible] = useState(false);
  const [isRelationFormVisible, setIsRelationFormVisible] = useState(false);
  const [isTaskFormVisible, setIsTaskFormVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [hoveredSphereId, setHoveredSphereId] = useState<
    null | string | boolean
  >(null);
  const [action, setAction] = useState<"create" | "edit" | null>(null);
  const [selectedNavKey, setSelectedNavKey] = useState("allpeople");
  const [sphereToUpdate, setSphereToUpdate] = useState<
    | {
        sphereId: string;
        sphereName: string;
        sphereColor: string;
      }
    | undefined
  >(undefined);
  const navigate = useNavigate();
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;

  const {
    data = {
      users: [
        { userSpheres: [{ sphereId: "", sphereName: "", sphereColor: "" }] },
      ],
    },
    refetch,
  } = useGetUsersSpheresQuery({
    variables: {
      where: { userId: loggedUserId },
    },
  });

  useEffect(() => {
    if (selectedSpheres.length) {
      navigate("/people-list", { state: { selectedSpheres: selectedSpheres } });
    } else if (
      selectedSpheres.length === 0 &&
      selectedNavKey !== "dashboard" &&
      selectedNavKey !== "allpeople" &&
      selectedNavKey !== "tasks" &&
      selectedNavKey !== "userAccount"
    ) {
      navigate("/people-list", { state: { selectedSpheres: [] } });
      setSelectedNavKey("allpeople");
    }
  }, [selectedSpheres, data]);

  const handleCheckboxChange = (
    checked: boolean,
    sphere: { sphereId: string; sphereName: string; sphereColor: string }
  ) => {
    if (checked) addSphere(sphere);
    else removeSphere(sphere.sphereId);
  };

  const handleItemClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    sphere: { sphereId: string; sphereName: string; sphereColor: string }
  ) => {
    // Prevent the event from propagating if clicking on the button or checkbox
    if (
      (e.target as HTMLElement).closest(".ant-btn") ||
      (e.target as HTMLElement).closest(".ant-checkbox-wrapper")
    ) {
      return;
    }
    handleCheckboxChange(
      !selectedSpheres.some((item) => item.sphereId === sphere.sphereId),
      sphere
    );
  };

  return (
    <>
      {" "}
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        breakpoint="lg"
        className="navbar-desktop"
      >
        <Menu
          theme="dark"
          defaultSelectedKeys={["dashboard"]}
          selectedKeys={[selectedNavKey]}
          mode="inline"
        >
          <Menu.Item key="dashboard" icon={<HomeOutlined />}>
            <Link
              to="/"
              onClick={() => {
                setSelectedSpheres([]);
                setSelectedNavKey("dashboard");
              }}
            >
              Dashboard
            </Link>
          </Menu.Item>
          <Menu.SubMenu
            key="spheres-header"
            icon={<BlockOutlined />}
            title="Spheres"
          >
            <Menu.Item
              key="allpeople"
              icon={<TeamOutlined />}
              style={{
                paddingLeft: "8px",
                borderBottom: "1px solid rgb(80, 80, 80)",
              }}
            >
              <Link
                to="/people-list"
                state={{ selectedSpheres: [] }}
                onClick={() => {
                  setSelectedSpheres([]);
                  setSelectedNavKey("allpeople");
                }}
              >
                All People
              </Link>
            </Menu.Item>
            {data?.users[0]?.userSpheres
              .slice()
              .sort((a, b) => a.sphereName.localeCompare(b.sphereName))
              .map((item) => (
                <Menu.Item
                  key={item.sphereId}
                  onMouseEnter={() => setHoveredSphereId(item.sphereId)}
                  onMouseLeave={() => setHoveredSphereId(false)}
                  style={{ paddingLeft: "8px" }}
                >
                  <div
                    className="navbar-item"
                    onClick={(e) => {
                      handleItemClick(e, item);
                      setSelectedNavKey(item.sphereId);
                    }}
                  >
                    <Checkbox
                      checked={selectedSpheres.some(
                        (sphere) => sphere.sphereId === item.sphereId
                      )}
                      onChange={(e) =>
                        handleCheckboxChange(e.target.checked, {
                          sphereId: item.sphereId,
                          sphereName: item.sphereName,
                          sphereColor: item.sphereColor
                            ? item.sphereColor
                            : "#1677ff",
                        })
                      }
                      style={{ color: "white", padding: "8px 0px" }}
                    ></Checkbox>
                    <p
                      style={{
                        width: "160px",
                        wordBreak: "break-word",
                        whiteSpace: "wrap",
                        lineHeight: "1.5",
                        paddingLeft: "8px",
                      }}
                    >
                      {item.sphereName}
                    </p>
                    <Button
                      style={
                        hoveredSphereId === item.sphereId
                          ? { opacity: 1 }
                          : { opacity: 0 }
                      }
                      type="link"
                      onClick={() => {
                        setAction("edit");
                        setIsSphereFormVisible(true);
                        setSphereToUpdate({
                          sphereId: item.sphereId,
                          sphereName: item.sphereName,
                          sphereColor: item.sphereColor,
                        });
                      }}
                    >
                      <EditOutlined
                        style={{
                          cursor: "pointer",
                          color: "white",
                          backgroundColor: "#3a86ff",
                          padding: "8px",
                          borderRadius: "4px",
                        }}
                      />
                    </Button>
                  </div>
                </Menu.Item>
              ))}
          </Menu.SubMenu>
          <Menu.Item key="tasks" icon={<CheckSquareOutlined />}>
            <Link
              to="/tasks"
              onClick={() => {
                setSelectedSpheres([]);
                setSelectedNavKey("tasks");
              }}
            >
              Tasks
            </Link>
          </Menu.Item>
          <Menu.SubMenu
            key="add-header"
            icon={<PlusCircleOutlined />}
            title="Add"
            className="add-submenu"
          >
            {[
              {
                key: "add-sphere",
                label: "Add Sphere",
                icon: <PlusCircleOutlined />,
                onClick: () => {
                  setAction("create");
                  setIsSphereFormVisible(true);
                },
              },
              {
                key: "add-person",
                label: "Add Person",
                icon: <PlusCircleOutlined />,
                onClick: () => {
                  navigate("/person-form/create");
                },
              },
              {
                key: "add-relationship",
                label: "Add Relationship",
                icon: <PlusCircleOutlined />,
                onClick: () => {
                  setIsRelationFormVisible(true);
                },
              },
              {
                key: "add-task",
                label: "Add Task",
                icon: <PlusCircleOutlined />,
                onClick: () => {
                  setIsTaskFormVisible(true);
                },
              },
            ].map((item) => (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                style={{ paddingLeft: "8px" }}
              >
                <Button
                  type="link"
                  onClick={item.onClick}
                  className="menu-button"
                  ghost
                >
                  {item.label}
                </Button>
              </Menu.Item>
            ))}
          </Menu.SubMenu>

          <Menu.Item key="userAccount" icon={<UserOutlined />}>
            <Link
              to="/my-account"
              onClick={() => {
                setSelectedSpheres([]);
                setSelectedNavKey("userAccount");
              }}
            >
              My Account
            </Link>
          </Menu.Item>
        </Menu>
        {isSphereFormVisible && (
          <SphereForm
            visible={isSphereFormVisible}
            onCancel={(param?: any) => {
              setIsSphereFormVisible(false);
              if (action === "create" && param.sphereId) {
                refetch();
                setSelectedSpheres([...selectedSpheres, param]);
              }
              if (action === "edit" && param.sphereId) {
                refetch();
                setSelectedSpheres(
                  selectedSpheres.map((sphere) =>
                    sphere.sphereId === param.sphereId ? param : sphere
                  )
                );
              }
              if (action === "edit" && param === "deleted") {
                refetch();
                setSelectedSpheres(
                  selectedSpheres.filter(
                    (sphere) => sphere.sphereId !== sphereToUpdate?.sphereId
                  )
                );
              }
            }}
            action={action}
            sphere={sphereToUpdate}
          />
        )}
        {isRelationFormVisible && (
          <RelationForm
            visible={isRelationFormVisible}
            loggedUserId={loggedUserId}
            onCancel={(param?: any) => {
              setIsRelationFormVisible(false);
              refetch();
            }}
          />
        )}
        {isTaskFormVisible && (
          <TaskForm
            visible={isTaskFormVisible}
            loggedUserId={loggedUserId}
            onCancel={() => {
              setIsTaskFormVisible(false);
              refetch();
            }}
          />
        )}
      </Sider>
      <ResponsiveNavbar
        selectedNavKey={selectedNavKey}
        setSelectedNavKey={setSelectedNavKey}
        setSelectedSpheres={setSelectedSpheres}
        setIsSphereFormVisible={setIsSphereFormVisible}
        setIsRelationFormVisible={setIsRelationFormVisible}
        setIsTaskFormVisible={setIsTaskFormVisible}
        userSpheres={data?.users[0]?.userSpheres || []}
        hoveredSphereId={hoveredSphereId}
        setHoveredSphereId={setHoveredSphereId}
        handleItemClick={handleItemClick}
        selectedSpheres={selectedSpheres}
        handleCheckboxChange={handleCheckboxChange}
        setAction={setAction}
        setSphereToUpdate={setSphereToUpdate}
      />
    </>
  );
};

export default Navbar;
