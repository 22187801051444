import {
  Button,
  Input,
  InputRef,
  Space,
  Table,
  TableColumnType,
  Tag,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { getCookie } from "../../hooks/cookie";
import {
  Task,
  UpdateTasksDocument,
  useGetPersonTasksQuery,
  useGetTaskStatusQuery,
} from "../../../generated/graphql";
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import "../../../style/tasks-list.scss";

type DataIndex = keyof Task;

const TaskList = () => {
  const navigate = useNavigate();
  const userCookie = getCookie("user");
  const userObj = userCookie
    ? JSON.parse(decodeURIComponent(userCookie))
    : null;
  const loggedUserId = userObj ? userObj.userId : null;
  const { data: tasks, refetch: refetchTasks } = useGetPersonTasksQuery({
    variables: {
      where: {
        taskOwner: { userId: loggedUserId },
      },
    },
  });
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const { data: taskStatus } = useGetTaskStatusQuery();
  const taskStatuses = taskStatus?.__type?.enumValues;
  const [updateTask] = useMutation(UpdateTasksDocument);

  useEffect(() => {
    refetchTasks();
  }, [tasks]);

  const findTask = (taskId: string) => {
    return tasks?.tasks?.find((task) => task.taskId === taskId);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<Task> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedKeys([value]);
            }}
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />

          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                if (clearFilters) {
                  clearFilters();
                  setSearchText("");
                  setSelectedKeys([]);
                  confirm();
                }
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()) ?? false
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns: TableColumnType<any>[] = [
    {
      title: "Task",
      fixed: "left",
      dataIndex: "taskName",
      key: "taskName",
      ...(getColumnSearchProps("taskName") as any),
      render(value, record) {
        return (
          <div>
            <p>{value}</p>
            <p style={{ fontSize: "12px", color: "#777" }}>
              {record.taskDescription}
            </p>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "taskStatus",
      key: "taskStatus",
      render(value, record) {
        return (
          <div className="non-clickable-column">
            {taskStatuses?.map((status) => (
              <Tag.CheckableTag
                style={{ position: "relative", zIndex: 10 }}
                key={`${record.taskId}-${status.name}`}
                checked={
                  findTask(record.taskId)?.taskStatus === status.name &&
                  findTask(record.taskId)?.taskId === record.taskId
                    ? true
                    : false
                }
                onChange={async (checked) => {
                  if (checked) {
                    await updateTask({
                      variables: {
                        update: {
                          taskStatus: status.name,
                          taskUpdatedAt: dayjs().format("YYYY-MM-DD"),
                        },
                        where: {
                          taskId: record.taskId,
                          taskPerson_SINGLE: {
                            personId: record.taskPerson[0].personId,
                          },
                          taskOwner: { userId: loggedUserId },
                        },
                      },
                    }).then(() => {
                      refetchTasks();
                    });
                  }
                }}
              >
                {status.name.replace(/_/g, " ")}
              </Tag.CheckableTag>
            ))}
          </div>
        );
      },
      sorter: (a, b) => a.taskStatus.localeCompare(b.taskStatus),
    },
    {
      title: "Person",
      dataIndex: "taskPerson",
      key: "taskPerson",
      render(value, record, index) {
        return value[0]?.personName;
      },
      sorter: (a, b) =>
        a.taskPerson[0]?.personName.localeCompare(b.taskPerson[0]?.personName),
    },
  ];

  return (
    <div className="tasks-list">
      <div className="page-title-header">
        <h1>{"tasks".toUpperCase()}</h1>
      </div>
      <Space
        style={{
          marginBottom: 16,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      ></Space>
      <Table
        columns={columns}
        dataSource={tasks?.tasks}
        onRow={(record) => ({
          onClick: (e) => {
            if ((e.target as Element).closest(".non-clickable-column")) {
              e.stopPropagation();
              return;
            }
            navigate(`/person/${record.taskPerson[0]?.personName}`, {
              state: { personId: record.taskPerson[0]?.personId },
            });
          },
        })}
      />
    </div>
  );
};

export default TaskList;
